import PropTypes from 'prop-types';
import AnswerPropType from './AnswerPropType';

export default PropTypes.shape({
  /*
   *  defines the type of question
   */
  questionType: PropTypes.string,
  /*
   *  it defines the element text Title
   */
  title: PropTypes.string,
  /*
   *  it defines the second Title of element
   */
  subtitle: PropTypes.string,
  /*
   *  it defines the extra info of the element
   */
  extrainfo: PropTypes.string,
  /*
   *  defines the text of the element button
   */
  textButton: PropTypes.string,
  /*
   *  it defines the defaultValue of the element
   */
  defaultValue: PropTypes.string,
  /*
   *  it defines the project id
   */
  project_ids: PropTypes.arrayOf(PropTypes.string),
  /*
   *  it defines the option answer
   */
  answer: PropTypes.arrayOf(AnswerPropType),
  /*
   *  it defines the image or Background the element
   */
  image: PropTypes.string,
  /*
   *  it defines the regex the element
   */
  regex: PropTypes.string,
  /*
   *  it defines the order of question
   */
  order: PropTypes.number,
  /*
   *  it defines if the question is disable
   */
  disabled: PropTypes.bool,
  /*
   *  it defines if the question if is not possible skipping
   */
  required: PropTypes.bool,
  /*
   *  it defines the additionalInfoKey
   */
  additionalInfoKey: PropTypes.string,
  /*
   *  defines the disposition of the option
   */
  disposition: PropTypes.string,
});
