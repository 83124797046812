import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useAnswerCounter } from 'API/queries/answerCounter';
import { useRemoveAnswerCounter } from 'API/queries/removeAnswerCounter';
import { surveyVar } from 'API/variables';
import { LazyImage } from 'components/LazyImage';
import { ContactForm } from 'components/ContactForm';
import { TransitionSlide } from 'components/TransitionSlide';
import { useSubmitSurvey } from 'API/queries/submit';
import { getHostname } from 'utils/urlParser';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { getIndexFromID } from './utils/getIndexFromID.js';

// styles
import './slide.scss';
import QuestionPropType from '../../utils/propType/QuestionPropType';
import { SlideContent } from '../SlideContent';

const Slide = ({ data, isFirstAnswer, setFirstAnswer }) => {
  const { t } = useTranslation();
  const [slideNextValue, setSlideNextValue] = useState(0);
  const [answerCounter] = useAnswerCounter();
  const [removeAnswerCounter] = useRemoveAnswerCounter();
  const [values, setValues] = useState([]);
  const [questionsPath, setQuestionsPath] = useState([]);
  const totalSlides = data.length - 1;

  const [showLastPage, setShowLastPage] = useState(false);
  const [submitSurvey, survey] = useSubmitSurvey();

  const resultPage = () => {
    if (survey.error) {
      setShowLastPage(false);
    } else {
      window.location.replace(`http:\\\\${getHostname()}`);
    }
  };

  const propsValueLastSlide = {
    image: null,
    className: survey.error && 'error',
    title: survey.error ? t('upsSomethingWentWrong') : t('surveyCompletedSuccessfully'),
    subtitle: survey.error ? t('pleaseTryAgain') : t('clickTheBelowButtonToBeRedirected'),
    textButton: t('clickHere'),
  };

  const updateValues = (oldAnswerId, newAnswerId) => {
    const newValues = [...values];
    if (newValues.includes(oldAnswerId)) {
      newValues.splice(newValues.indexOf(oldAnswerId), 1);
    } else {
      newValues.push(oldAnswerId);
    }
    if (newAnswerId) {
      if (newValues.includes(newAnswerId)) {
        newValues.splice(newValues.indexOf(newAnswerId), 1);
      } else {
        newValues.push(newAnswerId);
      }
    }
    setValues(newValues);
  };

  const goToNextQuestion = useCallback(() => {
    setQuestionsPath([...questionsPath, data[slideNextValue].id]);
    let nextQuestionID = data[slideNextValue].nextQuestion;
    const answers_ids = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data[slideNextValue].answers.length; i++) {
      if (values.indexOf(data[slideNextValue].answers[i].id) !== -1) {
        // find next question index from id
        if (data[slideNextValue].answers[i].nextQuestion !== null) {
          nextQuestionID = data[slideNextValue].answers[i].nextQuestion;
        }
        answers_ids.push(data[slideNextValue].answers[i].id);
      }
    }
    // send info data to BE
    answerCounter({
      variables: {
        survey_id: surveyVar().id,
        question_id: data[slideNextValue].id,
        answer_id: answers_ids,
        isFirstAnswer,
      },
    });
    if (isFirstAnswer) {
      setFirstAnswer(false);
    }
    if (nextQuestionID !== null) {
      setSlideNextValue(getIndexFromID(nextQuestionID, data));
    } else {
      setSlideNextValue(slideNextValue + 1);
    }
  }, [questionsPath, values, slideNextValue, data, isFirstAnswer, setFirstAnswer]);

  const goToPrevQuestion = () => {
    const newQuestionsPath = [...questionsPath];
    const answers_ids = [];
    // remove info data from BE
    const questionIndex = getIndexFromID(questionsPath[questionsPath.length - 1], data);
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < data[questionIndex].answers.length; i++) {
      if (values.indexOf(data[questionIndex].answers[i].id) !== -1) {
        answers_ids.push(data[questionIndex].answers[i].id);
      }
    }
    removeAnswerCounter({
      variables: {
        survey_id: surveyVar().id,
        question_id: data[questionIndex].id,
        answer_id: answers_ids,
      },
    });
    // go back to previous page
    newQuestionsPath.splice(questionsPath.length - 1, 1);
    setQuestionsPath(newQuestionsPath);
    setSlideNextValue(getIndexFromID(questionsPath[questionsPath.length - 1], data));
  };
  const typeSlide = classNames('survey-slide', {
    none: data[slideNextValue] !== undefined && data[slideNextValue].layout === 'none',
    left: data[slideNextValue] !== undefined && data[slideNextValue].layout === 'left-full',
    right: data[slideNextValue] !== undefined && data[slideNextValue].layout === 'right-full',
    full: data[slideNextValue] !== undefined && data[slideNextValue].layout === 'full',
  });

  const imgBucket = data[slideNextValue]?.image ? encodeURI(process.env.REACT_APP_BUCKET_BASEURL + data[slideNextValue].image) : '';

  const SlideContentComponent = data[slideNextValue] !== undefined && data[slideNextValue].layout === 'full' ? LazyImage : 'div';
  const SlideContentComponentProps =
    data[slideNextValue] !== undefined && data[slideNextValue].layout === 'full'
      ? {
          isBackgroundImage: true,
          src: imgBucket,
        }
      : {};

  return (
    <SlideContentComponent className={typeSlide} {...SlideContentComponentProps}>
      <div className='slide-content'>
        {slideNextValue <= totalSlides && (
          <>
            {data.map(
              (item, slideNumber) =>
                slideNextValue === slideNumber && (
                  <SlideContent
                    item={item}
                    goNext={goToNextQuestion}
                    goPrev={goToPrevQuestion}
                    totalSlides={totalSlides}
                    slideNumber={slideNumber}
                    key={`slide ${item.id}${item.order}`}
                    values={values}
                    updateValues={updateValues}
                  />
                ),
            )}
          </>
        )}
        {slideNextValue === totalSlides + 1 &&
          (showLastPage ? (
            <TransitionSlide {...propsValueLastSlide} goToNextQuestion={resultPage} />
          ) : (
            <ContactForm answerIds={values} setShowLastPage={setShowLastPage} submitSurvey={submitSurvey} />
          ))}
      </div>

      {slideNextValue <= totalSlides &&
        data[slideNextValue].image !== null &&
        data[slideNextValue].image !== undefined &&
        data[slideNextValue].layout !== 'full' &&
        data[slideNextValue].layout !== 'none' && <LazyImage src={imgBucket} className='slide-question-image' isBackgroundImage={true} />}
      {slideNextValue > totalSlides && !showLastPage && (
        <LazyImage
          src={`${process.env.REACT_APP_BUCKET_BASEURL}mysurvey/img/slide.jpeg`}
          className='slide-question-image'
          isBackgroundImage={true}
        />
      )}
    </SlideContentComponent>
  );
};

Slide.propTypes = {
  /*
   *   array of questions
   */
  data: PropTypes.arrayOf(QuestionPropType).isRequired,
  isFirstAnswer: PropTypes.bool,
  setFirstAnswer: PropTypes.func,
};

export default React.memo(Slide);
