import { privacyVar } from 'API/variables';

export const validateField = (inputValue, label, countryCode, isPrivacyField, privacyRequired) => {
  // regex rules
  const nameRegex = /^([\p{L}\p{M}]+[,.]?[ ]?|[\p{L}\p{M}]+['-]?)+$/gmu;
  const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const telsRegex = {
    it: /^(39)(3\d{8,9}|0\d{5,10})$/, // landline + mobile
  };
  let isValid = false;
  switch (label) {
    case 'firstName':
      isValid = nameRegex.test(String(inputValue));
      break;
    case 'lastName':
      isValid = nameRegex.test(String(inputValue));
      break;
    case 'email':
      isValid = emailRegex.test(String(inputValue).toLowerCase());
      break;
    case 'tel':
      isValid = telsRegex[countryCode] ? telsRegex[countryCode].test(String(inputValue)) : true;
      break;
    default:
      isValid = false;
  }

  if (isPrivacyField) {
    isValid = (inputValue && privacyRequired) || !privacyRequired;
  }

  return isValid;
};

/**
 * This function elaborate the object passed as parameter
 * es {firstName:{value: 'myName', isValid: true}}
 * to return a new object with this shape
 * {firstName:'myName'}
 */
export const elaborateFormInput = (formFields) => {
  const privacyArray = [];
  const submitFormObject = {};
  Object.entries(formFields).forEach(([key, { value }]) => {
    const privacy = privacyVar().find((el) => (el.id || el.field) === key);
    if (privacy) {
      privacyArray.push({
        labelPolicy: privacy.text,
        policyId: key,
        value,
        required: privacy.required,
      });
    } else {
      submitFormObject[key] = value;
    }
  });
  submitFormObject.privacyInformation = privacyArray;
  return submitFormObject;
};
