import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import AnswerPropType from '../../utils/propType/AnswerPropType';
import { Option } from '../Option';

// styles
import './radioButtonsGroup.scss';

const RadioButtonsGroup = ({ options, showConfirmationButton, orientation, index, values, updateValues, type, goNext, labels }) => {
  const [goToNextQuestion, setGoToNextQuestion] = useState(false);

  const handleChange = (val) => {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < options.length; i++) {
      if (values.indexOf(options[i].id) !== -1) {
        updateValues(val, options[i].id);
        setGoToNextQuestion(true);
        return;
      }
    }
    updateValues(val);
    showConfirmationButton(true);
    setGoToNextQuestion(true);
  };

  useEffect(() => {
    if (goToNextQuestion) {
      setTimeout(() => {
        goNext();
      }, 400);
    }
    return () => {
      setGoToNextQuestion(false);
    };
  }, [goToNextQuestion, goNext, setGoToNextQuestion]);

  useEffect(() => {
    showConfirmationButton(options.some((item) => values.indexOf(item.id) >= 0));
  }, [options, showConfirmationButton, values]);

  return (
    <div data-testid='radioButtonsGroup-div-container' className='survey-radioButtonsGroup'>
      <div className={`radioButtonsGroup-${orientation}`}>
        {options.map((item, ii) => (
          <Option
            option={item}
            index={ii}
            value={values.includes(item.id)}
            handleChange={handleChange}
            key={`value:${item.label}itemId:${item.id}`}
            type={type}
            orientation={orientation}
            itemId={item.id}
          />
        ))}
      </div>
      {orientation.toUpperCase() === 'HORIZONTAL' && (
        <div className='range-label'>
          {labels &&
            labels.map((item) => (
              <label className='range-label' key={`range-label:${item}`}>
                {' '}
                {item}{' '}
              </label>
            ))}
        </div>
      )}
    </div>
  );
};

RadioButtonsGroup.propTypes = {
  /*
   *  it defines an array of AnswerPropType
   */
  options: PropTypes.arrayOf(AnswerPropType).isRequired,
  /*
   *  A callback to be performed to show/hide the confirmation buttoon
   */
  showConfirmationButton: PropTypes.func,
  /*
   *  it defines the orientation of the option
   */
  orientation: PropTypes.string,
  /*
   *  it defines the index of the slide
   */
  index: PropTypes.number.isRequired,
  /*
   * it defines the story of answer selected
   */
  values: PropTypes.arrayOf(PropTypes.string),
  /*
   * it defines the func for adding or delete an answer selected
   */
  updateValues: PropTypes.func.isRequired,
  type: PropTypes.string,
  goNext: PropTypes.func,
  labels: PropTypes.arrayOf(PropTypes.string),
};

RadioButtonsGroup.defaultProps = {
  showConfirmationButton: undefined,
  orientation: 'VERTICAL',
  values: [],
};

export default React.memo(RadioButtonsGroup);
