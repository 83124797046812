import PropTypes from 'prop-types';

export default PropTypes.shape({
  /*
   *  id option
   */
  question_id: PropTypes.string,
  /*
   *  option text
   */
  value: PropTypes.string,
  /*
   *  defines the label of option
   */
  label: PropTypes.string,
  /*
   *  it defines the placeHolder of option
   */
  placeholder: PropTypes.string,
  /*
   *  it defines the text's minimum length
   */
  minLength: PropTypes.number,
  /*
   *  it defines the text's max length
   */
  maxLength: PropTypes.number,
});
