import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import whyDidYouRender from '@welldone-software/why-did-you-render';
import { Spinner } from '../../components/Spinner';
import { projectInfoVar, surveyVar, startingSurveyTime, questionsVar, privacyVar, languageVar } from '../../API/variables';
import { useSurveyData } from '../../API/queries/surveyData';
import './surveyApp.scss';
import { getHostname, getSurveyName } from '../../utils/urlParser';
import { loadScssFromDB } from '../../utils/loadScssFromDB';
import { generateManifest, loadFavicon } from '../../utils/HTMLInjector';
import { useIncreaseView } from '../../API/queries/view';
import { Slide } from '../../components/Slide';
import { TransitionSlide } from '../../components/TransitionSlide';

whyDidYouRender(React, {
  onlyLogs: true,
  titleColor: 'green',
  diffNameColor: 'darkturquoise',
});

const SurveyApp = () => {
  const { t } = useTranslation();
  // API call to get projectInfo + survey + questions
  const { surveyName } = useParams();
  const { data, loading, called } = useSurveyData(getHostname(), getSurveyName(surveyName));

  // API call to increase view, it requires survey ID stored into surveyVar
  const [increaseView] = useIncreaseView();
  const [isFirstAnswer, setFirstAnswer] = useState(true);
  const [deleteSurvey, setDeleteSurvey] = useState(false);

  const infos = data?.getQuestionsByPublicInfos;
  const project = data?.getQuestionsByPublicInfos.project;

  // API call to get projectInfo + survey + questions
  useEffect(() => {
    if (called && !loading && data && project) {
      generateManifest(project.manifestConfig);
      loadFavicon(project.displayName);
      projectInfoVar(project);
      surveyVar(infos.survey);
      questionsVar(infos.questions);
      if (project.mySurveyConfig?.style) {
        loadScssFromDB(project.mySurveyConfig.style);
      }

      // increase views and start timer
      if (infos?.questions?.length) {
        increaseView({ variables: { survey_id: infos.survey.id } });
        // save into reactive variable the survey start hour
        startingSurveyTime(new Date().toISOString());
      } else {
        setDeleteSurvey(true);
        surveyVar({ state: 'expired' });
      }
      // if the survey will handle different language maybe it will be required to do a language mapping to match, e.g, ITA with it-IT
      if (languageVar() === null || !project.policyFlags[languageVar().toLowerCase()]) {
        privacyVar(project.policyFlags.ita);
      } else {
        privacyVar(project.policyFlags[languageVar().toLowerCase()]);
      }
    }

    // Cleanup function
    return () => {
      projectInfoVar({});
      surveyVar({});
      questionsVar([]);
      privacyVar({});
      setDeleteSurvey(false);
    };
  }, [data]);

  let component;
  if (loading) {
    // Loading project infos
    component = (
      <div className='survey-page'>
        <div className='spinner-container'>
          <Spinner />
        </div>
      </div>
    );
  } else if (called && !infos?.questions?.length) {
    // Loading completed but no data found.
    component = (
      <div className='survey-transitionSlide pageNotFound' tabIndex='0'>
        <div className='transitionSlide-content'>
          <div className='transitionSlide-textView'>
            <div className='transitionSlide-title'>
              <label className='transitionSlide-title-text'> {t('pageNotFound')}</label>
            </div>
            <div className='transitionSlide-subtitle'>
              <label className='transitionSlide-subtitle-text'> {t('wrongUrlMsg')}</label>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (called && !deleteSurvey && !!infos?.survey && infos.survey.state !== 'expired') {
    // Loading completed, found a deleted/exprired survey
    component = (
      <div className='survey-page'>
        <Slide data={data.getQuestionsByPublicInfos?.questions} isFirstAnswer={isFirstAnswer} setFirstAnswer={setFirstAnswer} />
      </div>
    );
  } else {
    // Loading completed without errors, render the questions.
    component = (
      <TransitionSlide
        className={deleteSurvey ? 'error' : 'pageNotFound'}
        title={deleteSurvey ? t('surveyClosed') : t('pageNotFound')}
        subtitle={deleteSurvey ? t('surveyClosedInfoMsg') : t('wrongUrlMsg')}
        textButton={t('goBackToWebsite')}
      />
    );
  }

  return <div className='app-container'>{component}</div>;
};

// SurveyApp.whyDidYouRender = process.env.NODE_ENV !== 'production';
export default React.memo(SurveyApp);
