import React, { useState } from 'react';
import { Button, Input, InputLabel } from '@material-ui/core';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { projectInfoVar, surveyVar, startingSurveyTime, privacyVar, languageVar } from 'API/variables';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { validateField, elaborateFormInput } from './utils/validateForm';
import Privacy from './Privacy';

// styles
import './contactForm.scss';

const ContactForm = ({ answerIds, setShowLastPage, submitSurvey }) => {
  const { t } = useTranslation();
  const [showErrorText, setShowErrorText] = useState(false);

  /**
   * This state is used to save and check the user input
   */
  const [formValues, setFormValues] = useState(() => {
    const defaultValue = { value: '', isValid: null };
    // dinamically create privacy fields
    const privacyField =
      privacyVar() && privacyVar().length
        ? privacyVar().reduce((acc, p) => {
            acc[p.id || p.field] = { value: false, isValid: !p.required };
            return acc;
          }, {})
        : {};
    return { firstName: { ...defaultValue }, lastName: { ...defaultValue }, email: { ...defaultValue }, tel: { ...defaultValue }, ...privacyField };
  });

  const onSubmit = () => {
    const formInput = elaborateFormInput(formValues);
    // Find the first invalid object
    const hasError = Object.entries(formValues).find(([, { isValid }]) => !isValid);
    if (!hasError) {
      submitSurvey({
        variables: {
          survey_id: surveyVar().id,
          user: {
            ...formInput,
            project_id: projectInfoVar().id,
            surveyInfo: {
              startTime: startingSurveyTime(),
              endTime: new Date().toISOString(),
              isCompleted: true,
              answers: answerIds,
            },
          },
          lang: languageVar() || 'ita',
        },
      }).catch((e) => e);
      // redirect to successful or error page
      setShowLastPage(true);
    } else {
      setShowErrorText(true);
    }
  };

  const handleOnChange = (value, field, countryCode, isPrivacyField, privacyRequired) => {
    setFormValues({
      ...formValues,
      [field]: {
        value,
        isValid: validateField(value, field, countryCode, isPrivacyField, privacyRequired),
      },
    });
  };

  return (
    <div className='survey-form'>
      <div className='form-registry'>
        <h2 className='title'>{t('fillTheForm')}</h2>
        <form className='form'>
          <div className='form-option'>
            <InputLabel
              required
              classes={{
                root: !formValues.firstName.isValid && showErrorText ? 'option-label Mui-error' : 'option-label',
              }}
            >
              {t('name')}
            </InputLabel>
            <Input
              classes={{
                root: `option-input${!formValues.firstName.isValid && showErrorText ? ' Mui-error' : ''}`,
              }}
              value={formValues.firstName.value}
              onChange={(event) => handleOnChange(event.target.value, 'firstName')}
            />
            <label className={`Mui-error${!formValues.firstName.isValid && showErrorText ? '' : ' hidden'}`}>{t('validName')}</label>
          </div>
          <div className='form-option'>
            <InputLabel
              required
              classes={{
                root: !formValues.lastName.isValid && showErrorText ? 'option-label Mui-error' : 'option-label',
              }}
            >
              {t('surname')}
            </InputLabel>
            <Input
              name='cognome'
              classes={{
                root: `option-input${!formValues.lastName.isValid && showErrorText ? ' Mui-error' : ''}`,
              }}
              value={formValues.lastName.value}
              onChange={(event) => handleOnChange(event.target.value, 'lastName')}
            />
            <label className={`Mui-error${!formValues.lastName.isValid && showErrorText ? '' : ' hidden'}`}>{t('validSurname')}</label>
          </div>
          <div className='form-option'>
            <InputLabel
              required
              classes={{
                root: !formValues.email.isValid && showErrorText ? 'option-label Mui-error' : 'option-label',
              }}
            >
              {t('mail')}
            </InputLabel>
            <Input
              name='email'
              classes={{
                root: `option-input${!formValues.email.isValid && showErrorText ? ' Mui-error' : ''}`,
              }}
              value={formValues.email.value}
              onChange={(event) => handleOnChange(event.target.value, 'email')}
            />
            <label className={`Mui-error${!formValues.email.isValid && showErrorText ? '' : ' hidden'}`}>{t('validMail')}</label>
          </div>
          <div className='form-option'>
            <InputLabel
              required
              classes={{
                root: !formValues.tel.isValid && showErrorText ? 'option-label Mui-error' : 'option-label',
              }}
            >
              {t('phone')}
            </InputLabel>
            <PhoneInput
              country={languageVar()?.slice(0, 2) || 'it'}
              placeholder=''
              containerClass={`MuiInputBase-root MuiInput-root option-input MuiInput-underline${!formValues.tel.isValid && showErrorText ? ' Mui-error' : ''}`}
              inputClass='MuiInputBase-input MuiInput-input'
              value={formValues.tel.value}
              onChange={(tel, country) => handleOnChange(tel, 'tel', country.countryCode)}
            />
            <label className={`Mui-error${!formValues.tel.isValid && showErrorText ? '' : ' hidden'}`}>{t('validPhone')}</label>
          </div>
          <label className='text-privacy'>{t('personDeclare')}</label>
          <div className='form-option'>
            {!privacyVar() ? (
              <label>missing privacy </label>
            ) : (
              privacyVar().map((el) => <Privacy element={el} key={el.id || el.field} handleOnChange={handleOnChange} formValues={formValues} showErrorText={showErrorText} />)
            )}
          </div>
        </form>
        <div className='form-submit-btn'>
          <Button variant='contained' onClick={onSubmit}>
            {t('submit')}
          </Button>
        </div>
      </div>
    </div>
  );
};

ContactForm.propTypes = {
  /*
   * an array composed by all answers' ids
   */
  answerIds: PropTypes.arrayOf(PropTypes.string),
  /**
   * Callback to performe once the form is valid
   */
  setShowLastPage: PropTypes.func.isRequired,
  /**
   * Callback to perfome once the form is valid, to send data to BE
   */
  submitSurvey: PropTypes.func.isRequired,
};

ContactForm.defaultProps = {
  answerIds: [],
};

export default React.memo(ContactForm);
