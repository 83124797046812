import React, { useState } from "react";
import { Check } from "@material-ui/icons";
import { Button } from "@material-ui/core";
import PropTypes from "prop-types";
import classNames from "classnames";
import AnswerPropType from "../../utils/propType/AnswerPropType";
import "./option.scss";

/*
    Option component, show the formatted option to user inside question component, can be checkbox (or radio), or text.
*/
const Option = ({
  option,
  index,
  value,
  handleChange,
  type,
  orientation,
  disabled,
}) => {
  const confirmationOptionBoxChecked = classNames("option-box", {
    "option-box-checked": value,
  });

  const [checked, setChecked] = useState(false);

  const handleButtonClick = () => {
    handleChange(option.id);
    if (type.toUpperCase() === "RADIO") {
      setChecked(true);
    } else {
      setChecked(!checked);
    }
  };

  const attivationAnimationCLick = classNames(
    "option-checkbox",
    orientation.toLowerCase(),
    {
      clicked: checked,
      selected: value,
    }
  );

  return (
    <>
      <Button
        onClick={handleButtonClick}
        className={attivationAnimationCLick}
        disabled={disabled}
        data-testid="div-container-opt"
      >
        <div className="option-checkbox-container">
          {orientation.toUpperCase() !== "HORIZONTAL" && (
            <div className={confirmationOptionBoxChecked}>
              <label className="option-checkbox-label">
                {String.fromCharCode(index + "A".charCodeAt(0))}
              </label>
            </div>
          )}
          {option && (
            <label
              className="option-text-label"
              data-testid="div-container-option-checkbox-label"
            >
              {option.label}
            </label>
          )}
        </div>
        {orientation.toUpperCase() !== "HORIZONTAL" && (
          <Check className={value ? "option-icon" : "option-icon-hidden"} />
        )}
      </Button>
    </>
  );
};

Option.propTypes = {
  /*
   *  defines the element
   */
  option: AnswerPropType.isRequired,
  /*
   *  defines the index of the option
   */
  index: PropTypes.number,
  /*
   *  defines the value of option
   */
  value: PropTypes.bool,
  /*
   *  The callback to perform on option button click
   */
  handleChange: PropTypes.func,
  /*
   *  it defines the orientation of the option
   */
  orientation: PropTypes.string,
  /*
   *  it defines the type of option
   */
  type: PropTypes.string,
  disabled:PropTypes.boolean
};

Option.defaultProps = {
  index: 0,
  handleChange: undefined,
  value: false,
  orientation: "vertical",
  type: "RADIO",
  disabled: false
};

export default React.memo(Option);
