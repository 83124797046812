import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { SlideProgressionButtons } from '../SlideProgressionButtons';
import QuestionPropType from '../../utils/propType/QuestionPropType';
import { RadioButtonsGroup } from '../RadioButtonsGroup';
import { CheckboxesGroup } from '../CheckboxesGroup';

// styles
import './question.scss';

const Question = ({ index, goNext, goPrev, values, updateValues, question, questionsArray }) => {
  const { disposition, textButton, required, questionType, subtitle, answers, id, title, maxAnswers, layout, labels } = question;
  const [showButton, setShowButton] = useState(false);

  const filteredQuestionsArray = questionsArray.filter((item) => item.questionType !== 'initialPage' && item.questionType !== 'slide');

  const questionNumber = filteredQuestionsArray.findIndex((item) => item.id === id) + 1;

  const handleConfirmationClick = () => {
    setShowButton(false);
    goNext();
  };

  const confirmationShowButton = classNames('confirmation-button-under-question', {
    showButton: showButton && questionType && questionType.toUpperCase() === 'CHECKBOX',
  });

  const questionContainerClassList = classNames('question-container', {
    horizontal: disposition && disposition.toUpperCase() === 'HORIZONTAL',
    centered: layout === 'full' || layout === 'none',
  });

  return (
    <div data-testid='question-div-container' className='survey-question'>
      <div className={questionContainerClassList}>
        <div>
          <div className='question-index-block'>
            <label className='question-index'>{questionNumber}</label>
          </div>
          <div className='question-content'>
            <label className='question-title'> {title} </label>
            <label className='question-subtitle'>{subtitle}</label>
            {questionType && (questionType.toUpperCase() === 'RADIO' || questionType.toUpperCase() === 'RANGE') && (
              <RadioButtonsGroup
                options={answers}
                orientation={disposition}
                index={index}
                values={values}
                updateValues={updateValues}
                type={questionType}
                showConfirmationButton={setShowButton}
                goNext={goNext}
                labels={labels}
              />
            )}
            {questionType && questionType.toUpperCase() === 'CHECKBOX' && (
              <CheckboxesGroup options={answers} showConfirmationButton={setShowButton} index={index} updateValues={updateValues} values={values} type={questionType} maxAnswers={maxAnswers} />
            )}
            <div data-testid='confirmation-button' className={confirmationShowButton} onClick={() => handleConfirmationClick()}>
              <Button variant='contained' classes={{ root: `question-confirmation-button` }}>
                {textButton}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <SlideProgressionButtons
        goNext={goNext}
        goPrev={goPrev}
        disableNext={required && !showButton}
        disablePrev={index === 0}
        totalSlides={filteredQuestionsArray.length}
        slideNumber={questionNumber}
        isTransitionSlide={false}
      />
    </div>
  );
};

Question.propTypes = {
  /*
   *  defines the index of the slide
   */
  index: PropTypes.number.isRequired,
  /*
   *  callback function to perform to go to the next question
   */
  goNext: PropTypes.func.isRequired,
  /*
   *  callback function to perform to go to the prev question
   */
  goPrev: PropTypes.func.isRequired,
  /*
   * it defines the story of answer selected
   */
  values: PropTypes.arrayOf(PropTypes.string),
  /*
   * it defines the func for adding or delete an answer selected
   */
  updateValues: PropTypes.func.isRequired,
  /*
   *  question content
   */
  question: QuestionPropType.isRequired,
  /*
   * it defines the maximum of selectable options
   */
  maxAnswers: PropTypes.number,
  /**
   * If true is an slide of Transition , if false is a question
   */
  isTransitionSlide: PropTypes.bool,
  questionsArray: PropTypes.arrayOf(PropTypes.shape({}))
};

Question.defaultProps = {
  values: [],
  /*
   * it defines the unlimited of number selectable options
   */
  maxAnswers: undefined,
  isTransitionSlide: false,
};

export default React.memo(Question);
