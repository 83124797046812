import gql from 'graphql-tag';
import { SURVEY_DATA } from './queriesData';

export const queries = Object.freeze({
  questions: gql`  
  query getQuestionsByPublicInfos($hostname: String!, $survey_name: String!) {
    getQuestionsByPublicInfos(hostname: $hostname, survey_name: $survey_name) {
      ${SURVEY_DATA}
    }}`,
});
