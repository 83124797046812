import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import { LazyImage } from 'components/LazyImage';
import { SlideProgressionButtons } from 'components/SlideProgressionButtons';
// import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import { surveyVar } from 'API/variables';
import { getHostname } from 'utils/urlParser';

// styles
import './transitionSlide.scss';
// import { useTranslation } from 'react-i18next';

const TransitionSlide = ({ className, title, subtitle, textButton, goNext, goPrev, slideNumber }) => {
  // const { t } = useTranslation();
  const classList = classNames('survey-transitionSlide', { 'first-slide': !!slideNumber }, className);

  /**
   * backslash is seen as an escape character
   * so to write two backslashes, 4 are required
   */
  const href = slideNumber === undefined && getHostname() ? `https:\\\\${getHostname()}` : '#';
  return (
    <div
      className={classList}
      onKeyDown={(e) => {
        if (e.code === 'Enter' && goNext) {
          goNext();
        }
      }}
      tabIndex='0'
    >
      <div className='transitionSlide-content'>
        {surveyVar().logo && (
          <div className='transitionSlide-logo'>
            <LazyImage src={surveyVar().logo} alt={title} />
          </div>
        )}
        <div className='transitionSlide-textView'>
          <div className='transitionSlide-title'>
            <label className='transitionSlide-title-text'> {title}</label>
          </div>
          {subtitle && (
            <div className='transitionSlide-subtitle'>
              <label className='transitionSlide-subtitle-text'> {subtitle}</label>
            </div>
          )}
          <div className='transitionSlide-button'>
            <Button variant='contained' classes={{ root: `confirmation-button-slide` }} onClick={goNext} href={href}>
              {textButton}
            </Button>
            {/*  commented because this feature doesn't work very well
            <div className="transitionSlide-button-indication-key">
              <label className="transitionSlide-button-indication">
                {t("pressEnter")}
              </label>
              <SubdirectoryArrowLeftIcon />
            </div> */}
          </div>
        </div>
      </div>
      {slideNumber !== 0 && goNext && goPrev && <SlideProgressionButtons goNext={goNext} goPrev={goPrev} isTransitionSlide={true} />}
    </div>
  );
};

TransitionSlide.propTypes = {
  /*
   *  it defines the Section Title
   */
  title: PropTypes.string.isRequired,
  /*
   *  it defines the second Title of section
   */
  subtitle: PropTypes.string,
  /*
   *  defines the section button text
   */
  textButton: PropTypes.string,
  /*
   *  it defines the name class of slide
   */
  className: PropTypes.string,
  /*
   *  defines the function where you can go to the next page
   */
  goNext: PropTypes.func,
  /*
   *  defines the function where you can back to the previous page
   */
  goPrev: PropTypes.func,
  slideNumber: PropTypes.number,
};

TransitionSlide.defaultProps = {
  subtitle: null,
  textButton: 'Start',
  className: null,
};

export default React.memo(TransitionSlide);
