import React from 'react';
import { Checkbox } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const Privacy = ({ element, handleOnChange, formValues, showErrorText }) => {
  const { t } = useTranslation();
  const fieldName = element.field || element.id;
  return (
    <div className='checkbox-container'>
      <Checkbox
        required
        onChange={(event) => handleOnChange(event.target.checked, fieldName, null, true, element.required)}
        color='primary'
        disableRipple
        classes={{
          root: !formValues[fieldName].isValid && showErrorText && element.required ? ' Mui-error' : '',
        }}
      />
      <div className='checkbox-text-container'>
        <label className='checkbox-label' dangerouslySetInnerHTML={{ __html: element.text }} />
        {!formValues[fieldName].isValid && showErrorText && element.required && <label className='Mui-error'>{t('acceptNotice')}</label>}
      </div>
    </div>
  );
};

Privacy.propTypes = {
  handleOnChange: PropTypes.func,
  showErrorText: PropTypes.bool,
  element: PropTypes.object,
  formValues: PropTypes.object,
};

export default React.memo(Privacy);
