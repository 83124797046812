import { useQuery } from '@apollo/client';
import { queries } from './queries';

// console.log(queries)
const useSurveyData = (hostname, survey_name) =>
  useQuery(queries.questions, {
    variables: {
      hostname,
      survey_name,
    },
  });

export default useSurveyData;
