import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useProjectInfo } from './API/queries/project';
import { TransitionSlide } from './components/TransitionSlide';
import { SurveyApp } from './view/Surveyapp';
import { projectInfoVar } from './API/variables';
import { Spinner } from './components/Spinner';

import 'theme/global.scss';

const IS_PROD = process.env.REACT_APP_NAMESPACE?.startsWith('biz-tecma-prod');

const App = () => {
  const { t } = useTranslation();
  const { data, loading } = useProjectInfo();

  if (data) {
    const projectInfo = data.getProjectInfoByHost;
    projectInfoVar(projectInfo);
  }

  const baseUrl = IS_PROD
    ? projectInfoVar()
        .enabledTools?.find((tool) => tool.name === 'MySurvey')
        ?.baseUrl?.replace('mysurvey-cms', 'mysurvey') || '/'
    : '/';

  if (IS_PROD && (loading || !projectInfoVar().enabledTools)) {
    return (
      <div className='project-info-spinner'>
        <Spinner />
      </div>
    );
  }

  return (
    <Router basename={baseUrl}>
      <Switch>
        <Route exact path={process.env.REACT_APP_GETHOSTNAME_MODE === 'hostname' ? '/:surveyName' : '/'} component={SurveyApp} />
        <Route component={() => <TransitionSlide className='pageNotFound' title={t('pageNotFound')} subtitle={t('wrongUrlMsg')} textButton={t('goBackToWebsite')} />} />
      </Switch>
    </Router>
  );
};

export default App;
