import { makeVar } from '@apollo/client';

/**
 * Variable used for local store
 */
export const projectInfoVar = makeVar({});
export const surveyVar = makeVar({});
export const startingSurveyTime = makeVar();
export const questionsVar = makeVar([]);
export const privacyVar = makeVar({});
export const languageVar = makeVar(null);
