// eslint-disable-next-line no-bitwise
const rgbToHex = ({ r, g, b }) => `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).split('.')[0]}`;

const darken = (rgb) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(rgb)) {
    // eslint-disable-next-line no-param-reassign
    rgb[key] -= (rgb[key] / 100) * 15;
  }
  return rgb;
};

const hexToRgb = (hex) => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

/**
 * This function creates global css variable loaded form DB
 */
export const loadScssFromDB = (data) => {
  // eslint-disable-next-line no-restricted-syntax
  for (const key of Object.keys(data)) {
    if (data[key] !== '') {
      if (key === 'textFont' || key === 'ctaFont') {
        loadFonts(key, data[key]);
      } else {
        document.documentElement.style.setProperty(`--${key}`, data[key]);
        if (key !== 'buttonTextColor') {
          document.documentElement.style.setProperty(`--${key}Light`, `${data[key]}7F`);
          document.documentElement.style.setProperty(`--${key}Lighter`, `${data[key]}19`);
          document.documentElement.style.setProperty(`--${key}Dark`, rgbToHex(darken(hexToRgb(data[key]))));
        }
      }
    }
  }
};

const loadFonts = (key, font) => {
  const link = document.createElement('link');
  link.setAttribute('rel', 'stylesheet');
  link.setAttribute('type', 'text/css');
  link.setAttribute('href', font.url);
  document.documentElement.style.setProperty(`--${key}`, font.name);
  document.head.appendChild(link);
};
