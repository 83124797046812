import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { logger } from '@tecma/logs-fe';
import { Option } from '../Option';
import AnswerPropType from '../../utils/propType/AnswerPropType';
// styles
import './checkboxesGroup.scss';

const CheckboxesGroup = ({ options, showConfirmationButton, updateValues, values, type, maxAnswers, index }) => {
  // Contains the ids of the answers selected by the user
  const [selectedOption, setSelectedOption] = useState([]);

  const handleChange = (item) => {
    const nextOptions = [...selectedOption];
    if (nextOptions.includes(item)) {
      nextOptions.splice(nextOptions.indexOf(item), 1);
      updateValues(item);
    } else if (nextOptions.length < maxAnswers || maxAnswers === null) {
      nextOptions.push(item);
      updateValues(item);
    } else {
      logger.info('errore,limite di selezione raggiunto');
    }
    setSelectedOption(nextOptions);
  };

  useEffect(() => {
    showConfirmationButton(options.some((item) => values.indexOf(item.id) >= 0));
  }, [options, showConfirmationButton, values]);

  // defines the status containing the ids of the answers based on what the user previously selected
  useEffect(() => {
    setSelectedOption(options.filter((item) => values.includes(item.id)).map((item) => item.id));
  }, [index, options, values]);

  return (
    <div data-testid='checkboxesGroup-div-container' className='survey-checkboxesGroup'>
      {options.map((item, ii) => (
        <Option
          option={item}
          index={ii}
          value={values.includes(item.id)}
          handleChange={handleChange}
          key={`value:${item.label}itemId:${item.id}`}
          type={type}
          disabled={selectedOption.length >= maxAnswers && !values.includes(item.id)}
        />
      ))}
    </div>
  );
};

CheckboxesGroup.propTypes = {
  /*
   *  it defines an array of AnswerPropType
   */
  options: PropTypes.arrayOf(AnswerPropType).isRequired,
  /*
   *  A callback to be performed to show/hide the confirmation buttoon
   */
  showConfirmationButton: PropTypes.func.isRequired,
  /*
   *  it defines the index of the slide
   */
  index: PropTypes.number.isRequired,
  /*
   * it defines the story of answer selected
   */
  values: PropTypes.arrayOf(PropTypes.string),
  /*
   * it defines the func for adding or delete an answer selected
   */
  updateValues: PropTypes.func.isRequired,
  /*
   * "it defines the maximum of selectable options"
   */
  maxAnswers: PropTypes.number,
  type: PropTypes.string,
};

CheckboxesGroup.defaultProps = {
  values: [],
  maxAnswers: undefined,
};
export default React.memo(CheckboxesGroup);
