import { useQuery } from '@apollo/client';
import { getHostname } from 'utils/urlParser';
import { queries } from './queries';

/**
 * Hooks to retrieve project information
 */
const useProjectInfo = () =>
  useQuery(queries.projectInfo, {
    variables: {
      host: getHostname(),
      url: window.location.href,
    },
  });

export default useProjectInfo;
