import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReportProblemOutlinedIcon from '@material-ui/icons/ReportProblemOutlined';
import { Spinner } from '../Spinner';
// styles
import './lazyImage.scss';

/**
 * This component could return a div with a backgroung image or a image, and it shows a spinner while the image is loading.
 * It depents on its props isBackgroundImage.
 *
 */
const LazyImage = ({ src, alt, className, isBackgroundImage, children, ...rest }) => {
  const { t } = useTranslation();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [loadingError, setLoadingError] = useState(false);
  useEffect(() => {
    setImageLoaded(false);
    const img = new Image();
    img.onload = () => setImageLoaded(true);
    img.onerror = () => setLoadingError(true);
    img.src = src;
    return () => setImageLoaded(false);
  }, [src]);

  let component = null;
  if (isBackgroundImage) {
    component = (
      <div className={className} style={{ backgroundImage: `url(${src})` }} {...rest}>
        {loadingError && (
          <div className='error-image-container'>
            <ReportProblemOutlinedIcon fontSize='large' classes={{ root: 'error-image' }} />
            <label className='error-image-label'>{t('cannotLoadImg')}</label>
          </div>
        )}
        {!imageLoaded && !loadingError && <Spinner />}
        {imageLoaded && children}
      </div>
    );
  } else {
    component = imageLoaded ? <img src={src} alt={alt} /> : <Spinner />;
  }

  return component;
};

LazyImage.propTypes = {
  /*
   * image link source
   */
  src: PropTypes.string.isRequired,
  /*
   * it defines the alt attribute of image tag
   */
  alt: PropTypes.string,
  /*
   * if true generate div with backgroundImage, otherway a simple img
   */
  isBackgroundImage: PropTypes.bool,
};
LazyImage.defaultProps = {
  isBackgroundImage: false,
  alt: '',
};

export default React.memo(LazyImage);
