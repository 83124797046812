import gql from 'graphql-tag';
import { REMOVE_ANSWER_COUNTER } from './queriesData';

export const queries = Object.freeze({
  removeAnswerCounter: gql`
  mutation resetQuestion($survey_id: ID!, $question_id: ID!, $answer_id: [ID]!){
    resetQuestion(survey_id: $survey_id, question_id: $question_id, answer_id: $answer_id){
      ${REMOVE_ANSWER_COUNTER}
    }}`,
});
