/* import LanguageDetector from 'i18next-browser-languagedetector'; */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { languageVar } from 'API/variables';
import { languagesMapping } from 'utils/languagesMapping';

const urlLang = new URLSearchParams(window.location.search).get('lang');
languageVar(urlLang);
const fallbackLng = urlLang ? languagesMapping[urlLang.toUpperCase()] : 'it-IT';
i18n
  /**
   * The following line has been commented out to avoid use users' pc language as default cms language
   * As of now (28/08/2021) survey app main language is italian.
   */
  /* .use(LanguageDetector)// detect user language */
  .use(initReactI18next) // passes i18n down to react-i18next
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  .init({
    // load multiple translation files
    ns: ['common'],
    defaultNS: 'common',
    fallbackLng, // use en if detected lng is not available
    load: 'currentOnly', // strategy to define which language codes to lookup.  check https://www.i18next.com/overview/configuration-options
    interpolation: {
      escapeValue: false,
    },
    react: {
      // do not touch this thing unless you really know what you're doing!
      useSuspense: false,
    },
    backend: {
      loadPath: './locales/{{lng}}/common.json',
    },
  });

export default i18n;
