/*
  3 hostname catch supported:
  - env: hostname hardcoded in .env file from REACT_APP_HOST
  - hostname: get from hostname example: surveyapp.arborealiving.it -> arborealiving.it
  - path: hostname parameter from url example: localhost:3000/?hostname=www.arborealiving.com
  Each environment must be configurated by REACT_APP_GETHOSTNAME_MODE in .env file

  survey name supported as survey parameter from path example: localhost:3000/?survey=A_new_Survey
*/
import { logger } from '@tecma/logs-fe';

/*
  get hostname following configuration mode
*/
export const getHostname = () => {
  let hostname;
  switch (process.env.REACT_APP_GETHOSTNAME_MODE) {
    case 'env': {
      hostname = process.env.REACT_APP_HOST;
      break;
    }
    case 'hostname': {
      hostname = `www.${window.location.hostname.substring(window.location.hostname.indexOf('.') + 1)}`;
      break;
    }
    case 'path': {
      const hostnameParam = new URLSearchParams(window.location.search).get('hostname');
      if (!hostnameParam || hostnameParam === '') {
        if (process.env.NODE_ENV !== 'production')
          logger.error('Missing hostname param');
      } else {
        hostname = hostnameParam;
      }
      break;
    }
    default: {
      if (process.env.NODE_ENV !== 'production')
        logger.error('Missing REACT_APP_GETHOSTNAME_MODE in .env');
    }
  }
  return hostname;
};

/*
  Try to get survey name from url, if fail error
*/
export const getSurveyName = (surveyNameInput) => {
  let surveyName;
  if (process.env.REACT_APP_GETHOSTNAME_MODE === 'hostname') {
    surveyName = surveyNameInput.replaceAll('_', ' ');
  } else {
    const surveyParam = new URLSearchParams(window.location.search).get('survey');
    surveyName = decodeURI(surveyParam).replaceAll('_', ' ');
    if (!surveyName && process.env.NODE_ENV !== 'production') {
      logger.error('Missing survey param');
    }
  }
  return surveyName;
};
