import gql from 'graphql-tag';
import { VIEW } from './queriesData';

export const queries = Object.freeze({
  view: gql`  
  mutation incrementViews($survey_id: ID!) {
    incrementViews(survey_id: $survey_id) {
      ${VIEW}
    }}`,
});
