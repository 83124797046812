import gql from 'graphql-tag';
import { PROJECT_DATA_TO_RETRIEVE } from './queriesData';

export const queries = Object.freeze({
  projectInfo: gql`
  query getProjectInfoByHost ($host :String!, $url:String) {
    getProjectInfoByHost (host :$host, url:$url) {
      ${PROJECT_DATA_TO_RETRIEVE}
    }
  }`,
});
