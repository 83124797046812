import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
// styles
import './spinner.scss';

const Spinner = () => (
  <div className='spinner-container'>
    <CircularProgress classes={{ root: 'spinner' }} />
  </div>
);

export default React.memo(Spinner);
