import gql from 'graphql-tag';
import { ANSWER_COUNTER } from './queriesData';

export const queries = Object.freeze({
  answerCounter: gql`
  mutation answerQuestion($survey_id: ID!, $question_id: ID!, $answer_id: [ID]!, $isFirstAnswer :Boolean!){
    answerQuestion(survey_id: $survey_id, question_id: $question_id, answer_id: $answer_id, isFirstAnswer: $isFirstAnswer){
      ${ANSWER_COUNTER}
    }}`,
});
