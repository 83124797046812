import gql from 'graphql-tag';
import { SUBMIT } from './queriesData';

export const queries = Object.freeze({
  submit: gql`
  mutation submitSurvey($survey_id: ID!, $user: SubmitSurveyInput!, $lang: String!) {
    submitSurvey(survey_id: $survey_id, user: $user, lang: $lang) {
      ${SUBMIT}
    }}`,
});
