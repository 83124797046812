export const SURVEY_DATA = `
  project{
    id
    logo
    mySurveyConfig
    policyFlags,
    manifestConfig,
    displayName
    enabledTools
  }
  survey{
    id
    name
    logo
    state
  }
  questions{
    id
    questionType
    disposition
    title
    subtitle
    description
    defaultAnswer
    maxAnswers
    answers{
      id
      value
      label
      placeholder
      minLength
      maxLength
      nextQuestion
    }
    nextQuestion
    regex 
    required
    additionalInfoKey
    image
    textButton
    layout
    labels
  }
`;
