import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import NavigateNext from '@material-ui/icons/NavigateNext';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

// styles
import './slideProgressionButtons.scss';

const SlideProgressionButtons = ({ goNext, goPrev, disableNext, disablePrev, slideNumber, totalSlides, isTransitionSlide }) => {
  const { t } = useTranslation();
  const typeSlide = classNames('survey-slide-buttons', {
    question: !isTransitionSlide,
  });

  return (
    <div data-testid='slideProgressionButtons-div-container' className={typeSlide}>
      <label data-testid='label' className='buttons-label'>
        {t('question')} {slideNumber}/{totalSlides}{' '}
      </label>
      <div className='slide-buttons'>
        <IconButton data-testid='button-prev' disabled={disablePrev} classes={{ root: `slide-button prev` }} onClick={() => goPrev()}>
          <NavigateBefore />
        </IconButton>
        <IconButton data-testid='button-next' disabled={disableNext} classes={{ root: `slide-button next` }} onClick={() => goNext()}>
          <NavigateNext />
        </IconButton>
      </div>
    </div>
  );
};

SlideProgressionButtons.propTypes = {
  /**
   * The callback to be perform on next button clicl
   */
  goNext: PropTypes.func.isRequired,
  /**
   * The callback to be perform on prev button clicl
   */
  goPrev: PropTypes.func.isRequired,
  /**
   * If true, the next button is disabled
   */
  disableNext: PropTypes.bool,
  /**
   * If true, the prev button is disabled
   */
  disablePrev: PropTypes.bool,
  /**
   * The number of the current slide
   */
  slideNumber: PropTypes.number,
  /**
   * The number of total Slides
   */
  totalSlides: PropTypes.number,
  /**
   * If true is a Transition Slide, if false is a question
   */
  isTransitionSlide: PropTypes.bool,
};

SlideProgressionButtons.defaultProps = {
  disableNext: false,
  disablePrev: false,
  slideNumber: 0,
  totalSlides: 0,
  isTransitionSlide: false,
};

export default React.memo(SlideProgressionButtons);
