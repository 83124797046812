import React from 'react';
import PropTypes from 'prop-types';
import { useReactiveVar } from '@apollo/client';
import { questionsVar } from '../../API/variables';
import { Question } from '../Question';
import { TransitionSlide } from '../TransitionSlide';
import QuestionPropType from '../../utils/propType/QuestionPropType';
// styles of all type button
import './slideContent.scss';

const SlideContent = ({ item, goNext, goPrev, slideNumber, values, updateValues }) => {
  const isTransitionSlide = item.questionType.toLowerCase() === 'initialpage' || item.questionType.toLowerCase() === 'slide';
  const questionsArray = useReactiveVar(questionsVar);
  return (
    <>
      {isTransitionSlide ? (
        <TransitionSlide title={item.title} subtitle={item.subtitle} textButton={item.textButton} goNext={goNext} goPrev={goPrev} slideNumber={slideNumber} />
      ) : (
        questionsArray.length > 0 && (
          <Question
            index={slideNumber}
            goNext={goNext}
            goPrev={goPrev}
            values={values}
            updateValues={updateValues}
            isTransitionSlide={isTransitionSlide}
            question={item}
            questionsArray={questionsArray}
          />
        )
      )}
    </>
  );
};

SlideContent.propTypes = {
  /*
   *  slide content
   */
  item: QuestionPropType.isRequired,
  /*
   * A  callback function
   */
  goNext: PropTypes.func.isRequired,
  goPrev: PropTypes.func.isRequired,
  /*
   *  defines the index of the slide
   */
  slideNumber: PropTypes.number.isRequired,
  /*
   * it defines the story of answer selected
   */
  values: PropTypes.arrayOf(PropTypes.string),
  /*
   * it defines the func for adding or delete an answer selected
   */
  updateValues: PropTypes.func.isRequired,
};

SlideContent.defaultProps = {
  values: [],
};

export default React.memo(SlideContent);
