import React from 'react';
import ReactDOM from 'react-dom';
import { injectContextHeaders } from '@tecma/logs-fe';
import { ApolloProvider } from '@apollo/client';
import API from 'client/GraphqlClient';
import App from './App';
import './i18next';
import * as serviceWorker from './serviceWorker';

injectContextHeaders();

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={API}>
      <App />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

